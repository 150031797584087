/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { getCurrentLangKey } from 'ptz-i18n';
import { Helmet } from 'react-helmet';

import messages from '../locales_en';

import logo from '../../static/logo.png';
import 'intl';

const Layout = ({ children, location, maintenance, i18nMessages }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            languages {
              defaultLangKey
              langs
            }
          }
        }
      }
    `}
    render={(data) => {
      const url = typeof window !== 'undefined' ? location.pathname : '/';
      const { langs, defaultLangKey } = data.site.siteMetadata.languages;
      const langKey = getCurrentLangKey(langs, defaultLangKey, url);

      return (
        <IntlProvider
          locale={langKey || defaultLangKey}
          messages={i18nMessages || messages}
        >
          <div className="layout">
            <Helmet>
              <script
                src="https://kit.fontawesome.com/244838bf67.js"
                crossOrigin="anonymous"
              ></script>
            </Helmet>
            {!maintenance && (
              <nav
                id="main"
                className="navbar navbar-expand-lg navbar-light bg-white"
              >
                <div className="container">
                  <a className="navbar-brand" href="/">
                    <img
                      src={logo}
                      alt="Furlan Snowboards"
                      with="120"
                      height="120"
                    />
                  </a>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav">
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href={langKey === 'fr' ? '/fr/products' : '/en/products'}
                        >
                          {langKey === 'fr' ? 'Boards' : 'Boards'}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="https://store.furlansnowboards.com"
                        >
                          {langKey === 'fr' ? 'Vêtements' : 'Wear'}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href={langKey === 'fr' ? '/fr/blog' : '/en/blog'}
                        >
                          {langKey === 'fr' ? 'Blog' : 'Blog'}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href={langKey === 'fr' ? '/fr/team' : '/en/team'}
                        >
                          {langKey === 'fr' ? 'Famille' : 'Family'}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href={langKey === 'fr' ? '/fr/support' : '/en/support'}
                        >
                          {langKey === 'fr' ? 'Support' : 'Support'}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href={
                            langKey === 'fr' ? '/fr/contact-us' : '/en/contact-us'
                          }
                        >
                          {langKey === 'fr' ? 'Contact ' : 'Contact'}
                        </a>
                      </li>
                    </ul>
                    <ul className="navbar-nav right-items">
                      <li className="nav-item">
                        <a href="/fr" className="nav-link">
                          FR<span className="hide">ANCAIS</span>
                        </a>
                      </li>
                      <li className="nav-item mr-lg-3">
                        <a className="nav-link" href="/en">EN
                          <span className="hide">GLISH</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="https://twitter.com/FurlanSnowboard"
                          rel="noopener noreferrer"
                          target="_blank"
                          className="nav-link"
                        >
                          <i className="fab fa-twitter" />
                          <span className="hide">&nbsp;&nbsp;Twitter</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="https://www.instagram.com/furlansnowboards/?hl=fr"
                          rel="noopener noreferrer"
                          target="_blank"
                          className="nav-link"
                        >
                          <i className="fab fa-instagram" />
                          <span className="hide">&nbsp;&nbsp;Instagram</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="https://www.facebook.com/furlansnowboards/?ref=bookmarks"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="nav-link"
                        >
                          <i className="fab fa-facebook" />
                          <span className="hide">&nbsp;&nbsp;Facebook</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="https://www.youtube.com/channel/UCQL12Xx1Ohj97OiXHAw_vgQ"
                          rel="noopener noreferrer"
                          target="_blank"
                          className="nav-link"
                        >
                          <i className="fab fa-youtube" />
                          <span className="hide">&nbsp;&nbsp;Youtube</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="https://vimeo.com/furlan"
                          rel="noopener noreferrer"
                          target="_blank"
                          className="nav-link"
                        >
                          <i className="fab fa-vimeo" />
                          <span className="hide">&nbsp;&nbsp;Vimeo</span>
                        </a>
                      </li>

                    </ul>
                  </div>
                </div>
              </nav>
            )}
            <main>{children}</main>
            {!maintenance && (
              <footer className="main">
                <section className="cta2">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-10 offset-lg-1 my-auto text-center">
                        <a
                          href="https://twitter.com/FurlanSnowboard"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-2x fa-twitter mx-2 my-1" />
                        </a>
                        <a
                          href="https://www.instagram.com/furlansnowboards/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-2x fa-instagram mx-2 my-1" />
                        </a>
                        <a
                          href="https://www.facebook.com/furlansnowboards/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-2x fa-facebook mx-2 my-1" />
                        </a>
                        <a
                          href="https://www.youtube.com/channel/UCQL12Xx1Ohj97OiXHAw_vgQ"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-2x fa-youtube mx-2 my-1" />
                        </a>
                        <a
                          href="https://vimeo.com/furlan"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-2x fa-vimeo mx-2 my-1" />
                        </a>
                        <br />
                        <br />
                        <br />
                        <a
                          href={
                            langKey === 'fr' ? '/fr/newsletter' : '/en/newsletter'
                          }
                          className="btn btn-primary"
                        >
                          {langKey === 'fr' ? "Newsletter" : 'Newsletter'}
                        </a>
                      </div>
                    </div>
                  </div>
                </section>
                <blockquote className="blockquote-credit">
                  @damtess733 (Damien Rousse) / Justin Dutilh
                </blockquote>
                <nav className="navbar  navbar-light bg-white">
                  <div className="container">
                    <span className="navbar-text">
                      Furlan©{new Date().getFullYear()}
                    </span>
                    <span className="navbar-text">
                      <small>
                        &nbsp;&nbsp;by&nbsp;
                        <a
                          href="http://indiecrafts.dev"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          IndieCrafts
                        </a>
                      </small>
                    </span>
                    <ul className="navbar-nav">
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href={langKey === 'fr' ? '/fr/terms' : '/en/terms'}
                        >
                          {langKey === 'fr'
                            ? "Conditions d'utilisation"
                            : 'Terms and Data Condition'}
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </footer>
            )}
          </div>
        </IntlProvider>
      );
    }}
  />
);

export default Layout;
